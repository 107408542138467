import{i18n}from"@lingui/core";export default function getProductFilters(){return{aggregations:{event:{title:/*i18n*/{id:'Tasting sessions'},// multiSelect: false, // TODO Implement
size:20,get:function get(product){return product.collectionIds||[];},translations:{'62ecd29062842e10d25d8961':/*i18n*/{id:'Oct 31 AM'},'62ecd2b3f28b1e10cc33eb5d':/*i18n*/{id:'Oct 31 PM'},'62ecd2fe123f1410cadbbba4':/*i18n*/{id:'Nov 1 AM'},'62ecd313f394f910d1d6d29f':/*i18n*/{id:'Nov 1 PM'},'62ecd092f28b1e10cc33eb56':/*i18n*/{id:'Masterclass Oct 31'},'62ecd0b262842e10d25d895f':/*i18n*/{id:'Masterclass Nov 1'}}},highlights:{title:/*i18n*/{id:'Highlights'},conjunction:false,get:function get(product){var _product$gbmHighlight,_product$gbmHighlight2;console.log('product',product.isCertifiedOrganic);var gbmHighlights=((_product$gbmHighlight=product.gbmHighlights)===null||_product$gbmHighlight===void 0?void 0:(_product$gbmHighlight2=_product$gbmHighlight.values)===null||_product$gbmHighlight2===void 0?void 0:_product$gbmHighlight2.map(function(highlight){return highlight||'';}))||[];return[// Exclude 'None'
product!==null&&product!==void 0&&product.isCertifiedOrganic||product!==null&&product!==void 0&&product.isOrganic?'Organic':null,...gbmHighlights.filter(function(highlight){return highlight!=='None';})].filter(Boolean);},size:20},regionHierarchy:{title:/*i18n*/{id:'Winegrowing regions'},conjunction:false,// multiSelect: false,
size:100,get:function get(product){var _product$regionHierar;return((_product$regionHierar=product.regionHierarchy)===null||_product$regionHierar===void 0?void 0:_product$regionHierar.map(function(e){return e||'';}))||[];}// Excludes nulls
},priceRanges:{title:/*i18n*/{id:'Price ranges'},conjunction:false,sort:'term',order:'asc',// This comes from ProductsTemplate.afterQuery.
get:function get(product){var _product$pricing,_product$pricing2;if(!((_product$pricing=product.pricing)!==null&&_product$pricing!==void 0&&_product$pricing.price))return undefined;var price=(_product$pricing2=product.pricing)===null||_product$pricing2===void 0?void 0:_product$pricing2.price;if(price<15)return'$';if(price<25)return'$$';if(price<45)return'$$$';if(price<85)return'$$$$';return'$$$$$';},translations:{$:/*i18n*/{id:'Less than $15'},$$:/*i18n*/{id:'$15-25'},$$$:/*i18n*/{id:'$25-45'},$$$$:/*i18n*/{id:'$45-85'},$$$$$:/*i18n*/{id:'Greater than $85'}}},grapeVarieties:{title:/*i18n*/{id:'Grape varieties'},conjunction:false,size:300,get:function get(product){var _product$grapeVarieti;return(_product$grapeVarieti=product.grapeVarieties)===null||_product$grapeVarieti===void 0?void 0:_product$grapeVarieti.map(function(variety){return(variety===null||variety===void 0?void 0:variety.varietyName)||'';}).filter(Boolean);}// Excludes nulls
},productionMethods:{title:/*i18n*/{id:'Production methods'},conjunction:false,size:20,get:function get(product){var _product$characterist;var characteristics=(product===null||product===void 0?void 0:(_product$characterist=product.characteristics)===null||_product$characterist===void 0?void 0:_product$characterist.filter(function(value){return['VEGAN','ORGANIC','VEGETARIAN','BIODYNAMIC','KOSHER','SUSTAINABLE'].includes(value||'');}).map(function(e){return e||'';}))||[];return[...characteristics,// This is a hack because the resolver only pays attention to certified organic.
product!==null&&product!==void 0&&product.isCertifiedOrganic||product!==null&&product!==void 0&&product.isOrganic?'organic':null].filter(Boolean);},translations:{VEGAN:/*i18n*/{id:'vegan'},ORGANIC:/*i18n*/{id:'organic'},VEGETARIAN:/*i18n*/{id:'vegetarian'},BIODYNAMIC:/*i18n*/{id:'biodynamic'},KOSHER:/*i18n*/{id:'kosher'},SUSTAINABLE:/*i18n*/{id:'sustainable'}}},productType:{title:/*i18n*/{id:'Product types'},conjunction:false,get:function get(product){return product.wineType||// product.spiritType ||
// product.beerType ||
product.productType;},size:20,translations:{WINE:/*i18n*/{id:'Other wine'},SPIRIT:/*i18n*/{id:'Spirit'},Stillwein:/*i18n*/{id:'Wine'},BEER:/*i18n*/{id:'Beer and more'}}},country:{title:/*i18n*/{id:'Countries'},conjunction:false,multiSelect:false,size:100,get:function get(product){return product.country||undefined;}// Excludes nulls
},vintage:{title:/*i18n*/{id:'Vintages'},conjunction:false,size:50,sort:'term',order:'desc',get:function get(product){return product.vintage||undefined;},// Excludes nulls
translations:{NV:/*i18n*/{id:'NV'}}},classification:{title:/*i18n*/{id:'Classifications'},conjunction:false,size:50,get:function get(product){if(!(product!==null&&product!==void 0&&product.classification))return undefined;// Excludes nulls
if(product.classification==='----')return undefined;if(product.classification==='No classification')return undefined;return product.classification;}},specialClassification:{title:/*i18n*/{id:'Special classifications'},conjunction:false,size:50,get:function get(product){if(!(product!==null&&product!==void 0&&product.specialClassification))return undefined;// Excludes nulls
if(product.specialClassification==='----')return undefined;return product.specialClassification;}},designation:{title:/*i18n*/{id:'Designations'},conjunction:false,size:50,get:function get(product){return product.designation||undefined;}// Excludes nulls
}// closureTypes: {
//   title: defineMessage({ message: 'Closure types' }),
//   conjunction: false,
//   size: 50,
//   get: (product) => product.bottleVariants?.[0]?.closureType || undefined, // Excludes nulls
// },
},sortings:{byProducerName:{title:/*i18n*/{id:'Producer name'},field:[function(product){var _product$producer,_product$producer2;return(product===null||product===void 0?void 0:(_product$producer=product.producer)===null||_product$producer===void 0?void 0:_product$producer.sortName)||(product===null||product===void 0?void 0:(_product$producer2=product.producer)===null||_product$producer2===void 0?void 0:_product$producer2.name);}],order:'asc',group:'producer.producerId',groupHeader:'producer'},byProductName:{title:/*i18n*/{id:'Product name'},field:['shortName','vintage'],order:['asc','desc']}// byExhibitorName: {
//   title: defineMessage({ message: 'Exhibitor name' }),
//   field: [
//     'exhibitor.sortName',
//     'exhibitor.name',
//     'producer.sortName',
//     'producer.name',
//   ],
//   order: 'asc',
//   group: 'exhibitorId',
// },
// byStandNumber: {
//   title: defineMessage({ message: 'Table number' }),
//   field: [
//     sortBy.standName,
//     'exhibitor.sortName',
//     'exhibitor.name',
//     'producer.sortName',
//     'producer.name',
//   ],
//   order: 'asc',
//   group: 'registration.registrationId',
// },
}};}// Sort helper functions
var sortBy={standName:function standName(product){var _product$stand;return(product===null||product===void 0?void 0:(_product$stand=product.stand)===null||_product$stand===void 0?void 0:_product$stand.name.split(/[\s,-]+/).map(function(segment){return segment==='Tisch'?undefined:Number(segment)?segment.padStart(3,'0'):segment;}).filter(Boolean).join(' '))||'999';}};var fragment="2276959868";